<template>
  <div class="menu-body" v-if="
    $oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
  ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="outer-block">
      <div class="tab-titles">
        <div class="sample-list title-text">
          <div class="prior-years-actual"></div>
          <span class="sample-span">Completed</span>
          <div class="on-track"></div>
          <span class="sample-span">On Track</span>
          <div class="off-track"></div>
          <span class="sample-span">Off Track</span>
          <div class="committed"></div>
          <span class="sample-span">No Committed Date</span>
        </div>

        <div class="button-dd ">

          <button class="download-view action-btn" type="button" @click="exportToExcel" style="background: transparent; ">
            <img alt="download" src="../../../assets/download_icon.svg" />
          </button>

          <div class="funnel-con">
            <div class="action-btn primary-color" v-b-modal.action-filter>
              <b-icon icon="funnel"></b-icon>
            </div>
          </div>
          <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
            <ActionItemFilter :hideModal="hideModal" :filterArray="filterArray" :applyFilter="applyFilter"
              :resetFilter="resetFilter" :changedFilter="changedFilter" :filterSelected="filterSelected"
              :onSelect="onSelect" :onRemove="onRemove" />
          </b-modal>
        </div>
      </div>
      <hr class="hr" />

      <!-- Data Table -->
      <div id="table-div-reports-action" v-if="dataStatus">
        <b-table-simple responsive="true" id="reports-catv-data-table" outlined>
          <b-thead head-variant="light">
            <b-tr>
              <b-th colspan="4" class="sticky-column-1" style="z-index: 5"></b-th>
              <b-th style="z-index: 5" :colspan="region.count" v-for="(region, index) in regionList" :key="index">
                {{ region.label }}
              </b-th>
            </b-tr>
            <b-tr>
              <b-th colspan="1" class="sticky-column-1" style="top: 48px">Pillar Name</b-th>
              <b-th colspan="1" class="sticky-column-2" style="top: 48px">Practice No.</b-th>
              <b-th colspan="1" class="sticky-column-3" style="top: 48px">Principle No.</b-th>
              <b-th colspan="1" class="sticky-column-4" style="top: 48px">Level No.</b-th>
              <b-th style="min-width: 24rem !important; top: 48px" colspan="1" v-for="(plant, index) in plantList"
                :key="index">
                {{ plant }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-for="(draft, index) in DraftList" :key="index">
            <b-tr>
              <b-td class="sticky-column-1" :rowspan="draft.practiceDetails.length + 1">
                {{ draft.pillarCode }}
              </b-td>
            </b-tr>
            <b-tr v-for="(practice, practiceIndex) in draft.practiceDetails" :key="practiceIndex">
              <b-td class="sticky-column-2">{{ practice.practiceNo }}</b-td>
              <b-td class="sticky-column-3">{{ practice.principleNo }}</b-td>
              <b-td class="sticky-column-4">{{ practice.level }}</b-td>
              <b-td v-for="(action, actionIndex) in practice.plantDetails" :key="actionIndex"
                style="min-width: 24rem !important">
                <div :title="action.smartAction" v-if="action.smartAction && action.smartAction !== ''"
                  class="action-desc" :class="action.status">
                  {{ action.smartAction }}
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div class="no-data" v-else-if="!dataStatus">
        <img alt="no data" src="../../../assets/No_Data.svg" />
        <span class="ed-date">Please modify filters</span>
      </div>
      <!-- Data Table -->
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import Api from "@/common/constants/api";
import ApiCalls from "@/common/api/apiCalls";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import Helper from "@/common/helper/";
import XLSX from "xlsx";
import Role from "@/common/constants/roles";
import { mapGetters } from "vuex";
export default {
  name: "GapClosureDashboard",
  data() {
    return {
      DraftList: [],
      pillarDataBackup: {},
      regionList: [],
      roles: Role,
      plantList: [],
      finalData: [],
      pillarsData: [],
      pillarFields: {},
      showLoader: false,
      dataStatus: false,
      noOfFilters: 4,
      filterArray: [],
      filterSelected: [],
      isPlantUser: false,
      plantListFromAPI: [],
      pillarListFromAPI: [],
      onlyOneTime: true,
    };
  },
  components: {
    Breadcrumb,
    Loader,
    ApiCalls,
    Api,
    ActionItemFilter,
    // JsonExcel,
    XLSX,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 3,
      subMenu: 2,
    });
    this.$store.dispatch("breadcrumb", {
      // title: "Consolidated Action Tracker View",
      title: "Consolidated View",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Reports / ", primary: true, redirect: "/" },
        // { name: "Consolidated Action Tracker View", primary: false },
        { name: "Consolidated View", primary: false },
      ],
    });
  },
  computed: {
    ...mapGetters(["loggedInUserData"]),
  },
  created() {
    this.showLoader = true;
    this.filterArray = [];
    this.filterSelected = this.filterArray;
    this.getPlantList();
    if (
      this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.USER) ||
      this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER)
    )
      this.isPlantUser = true;
    else this.isPlantUser = false;
  },
  methods: {
    exportToExcel() {
      // On Click Excel download button
      let sheetData = [];
      console.log(this.pillarFields);
      this.pillarsData.forEach((i) => {
        let obj = {};
        for (const key in this.pillarFields) {
          if (
            Object.hasOwnProperty.call(this.pillarFields, key) &&
            !Object.hasOwnProperty.call(this.pillarFields[key], "field")
          ) {
            const element = this.pillarFields[key];
            obj[key] = i[element] ? i[element] : "";
          } else {
            const element = this.pillarFields[key];
            obj[key] = i[element.field].length
              ? element.callback(i[element.field])
              : "";
          }
        }
        sheetData.push(obj);
      });
      console.log(sheetData);
      // export json to Worksheet of Excel
      // only array possible
      let sheet = XLSX.utils.json_to_sheet(sheetData);

      let wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, sheet, "sheet"); // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, "Consolidated Action Actiontracker.xlsx"); // name of the file is 'book.xlsx'
    },
    downloadPdf() {
      this.download = true;
      Helper.export_table_to_excel("reports-catv-data-table", "Consolidated View");
    },

    getPlantList() {
      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
        this.plantListFromAPI = data.data;
        this.getPillarList();
      });
    },
    getPillarList() {
      ApiCalls.httpCallDetails(Api.GETPILLARDROPDOWNLIST, "get").then(
        (data) => {
          let arr = [];
          arr = data.data.map((val) => {
            if (val.active == "Y")
              return {
                id: val.pillarId,
                code: val.pillarName,
                pillarCode: val.pillarCode,
              };
          });
          arr = arr.filter((ele) => {
            if (ele) return ele;
          });
          this.pillarListFromAPI = arr;
          this.totalCallCount =
            this.plantList.length * this.pillarListFromAPI.length;
          this.showLoader = false;
          this.setFilterForAction();
          // }
        }
      );
    },
    getGapClosureData(plantIdsA, pillarCodesA) {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.GETCONSOLIDATEDACTIONTRACKERREPORT, "post", {
        plantIds: plantIdsA,
        pillarCodes: pillarCodesA,
      }).then((data) => {
        if (data && data.data) {
          this.pillarDataBackup = {};
          this.pillarsData = [];
          let arr = [];
          let objList = [];
          data.data.pillarData.forEach((pillarDataSingle) => {
            let key = pillarDataSingle.pillarCode + pillarDataSingle.practiceNo;
            if (Object.hasOwnProperty.call(objList, key)) {
              objList[key].smartActionItem =
                objList[key].smartActionItem +
                "\r\n\r\n ( " +
                pillarDataSingle.actionName +
                " ) " +
                pillarDataSingle.smartActionItem;
            } else {
              objList[key] = pillarDataSingle;
              objList[key].smartActionItem =
                " ( " +
                pillarDataSingle.actionName +
                " ) " +
                pillarDataSingle.smartActionItem;
            }
          });
          for (const key in objList) {
            if (Object.hasOwnProperty.call(objList, key)) {
              const element = objList[key];
              arr.push(element);
            }
          }
          data.data.pillarData = JSON.parse(JSON.stringify(arr));
          this.pillarDataBackup = data.data;
          this.pillarsData = this.pillarDataBackup.pillarData;
          this.pillarFields = {
            Region: "region",
            "Region PBU": "regionPBU",
            "Plant Name": "plantName",
            Pillar: "pillarCode",
            "Practice No.": "practiceNo",
            "Principle No.": "principleNo",
            Level: "levelNo",
            Action: "smartActionItem",
          };
          if (this.onlyOneTime) {
            this.setFilterForAction();
            this.onlyOneTime = false;
          }
          setTimeout(() => {
            this.actionDataCorrection();
            this.showLoader = false;
            this.dataStatus = true;
          }, 2000);
        } else {
          this.showLoader = false;
        }
      });
    },
    actionDataCorrection() {
      let regionList = {};
      let plantList = {};
      let pillarList = {};
      let principleNo = {};
      let levelNo = {};
      this.regionList = [];
      let pillarAraryL = [];
      let principleNoL = [];
      let levelNoL = [];
      this.plantList = [];
      let practiceNoList = [];
      let regionL = [];
      if (this.filterSelected && this.filterSelected.length > 0) {
        this.filterSelected[0].arrayValue.forEach((element, index) => {
          if (
            !regionList.hasOwnProperty(element.value) &&
            element.checked === true &&
            element.value !== "SelectAll"
          ) {
            regionList[element.value] = element.value;
            regionL.push(element.value);
          }
        });

        let count = 0;
        let plantName = [];
        this.plantList = [];
        for (const region of regionL) {
          count = 0;
          this.filterSelected[1].arrayValue.forEach((element, index) => {
            if (
              !plantList.hasOwnProperty(element.label.split(", ")[0]) &&
              element.checked === true &&
              region === element.label.split(", ")[1] &&
              element.value !== "SelectAll"
            ) {
              count++;
              plantList[element.label.split(", ")[0]] = element.label.split(
                ", "
              )[0];
              this.plantList.push(element.label.split(", ")[0]);
              plantName.push(element.label.split(", ")[0]);
            }
            if (
              index === this.filterSelected[1].arrayValue.length - 1 &&
              element.value !== "SelectAll"
            ) {
              this.regionList.push({
                label: region,
                count: count,
                plantName: plantName,
              });
              plantName = [];
              count = 0;
            }
          });
        }

        this.filterSelected[2].arrayValue.forEach((element, index) => {
          if (
            !pillarList.hasOwnProperty(element.value) &&
            element.checked === true &&
            element.value !== "SelectAll"
          ) {
            pillarList[element.value] = element.value;
            pillarAraryL.push(element.value);
          }
        });
        this.filterSelected[3].arrayValue.forEach((element, index) => {
          if (
            !principleNo.hasOwnProperty(element.value) &&
            element.checked === true &&
            element.value !== "SelectAll"
          ) {
            principleNo[element.value] = element.value;
            principleNoL.push(element.value);
          }
        });
        this.filterSelected[4].arrayValue.forEach((element, index) => {
          if (
            !levelNo.hasOwnProperty(element.value) &&
            element.checked === true &&
            element.value !== "SelectAll"
          ) {
            levelNo[element.value] = element.value;
            levelNoL.push(element.value);
          }
        });
      }
      this.pillarsData = this.pillarDataBackup.pillarData;
      let queuedPillarData = [];
      for (const pillar of pillarAraryL) {
        //// console.log(region);
        for (const plant of this.plantList) {
          for (const principle of principleNoL) {
            for (const level of levelNoL) {
              this.pillarsData.forEach((val) => {
                if (
                  val.plantName === plant &&
                  val.pillarCode === pillar &&
                  val.principleNo === principle &&
                  val.levelNo === level
                ) {
                  queuedPillarData.push(val);
                  //// console.log("in", queuedPillarData);
                }
              });
            }
          }
          //// console.log(plant);
          // for (const region of this.regionList) {
          // // console.log(pillar);
        }
        // }
      }
      //// console.log("out", queuedPillarData);
      this.pillarsData = queuedPillarData;
      //// console.log("after ", this.pillarsData);
      Object.values(pillarList).map((pillar) => {
        let practiceDetails = [];

        // Object.values(regionList).map((region) => {
        // let plantDetails = [];
        let count = 0;
        Object.values(plantList).map((plant, plantIndex) => {
          Object.values(principleNo).map((principle, principleIndex) => {
            Object.values(levelNo).map((level, levelIndex) => {
              count++;
              this.pillarDataBackup.pillarData.forEach((actualData) => {
                //// console.log(count,pillar,actualData.pillarCode,plant,actualData.plantName,actualData.practiceNo);
                if (
                  pillar === actualData.pillarCode &&
                  plant === actualData.plantName &&
                  level === actualData.levelNo &&
                  principle === actualData.principleNo
                ) {
                  practiceDetails.push({
                    practiceNo: actualData.practiceNo,
                    principleNo: actualData.principleNo,
                    level: actualData.levelNo,
                    plantName: [plant],
                    indexPlant: [plantIndex],
                    totalPlantCount: this.plantList.length,

                    plantDetails: [
                      {
                        smartAction: actualData.smartActionItem,
                        status: actualData.status,
                      },
                    ],
                  });
                }
              });
            });
          });
        });
        // });
        if (practiceDetails.length > 0) {
          practiceDetails = practiceDetails.filter((item, pos, self) => {
            let prevIndex = self.findIndex(
              (t) =>
                t.practiceNo === item.practiceNo &&
                t.principleNo === item.principleNo &&
                t.level === item.level
            );
            if (pos !== prevIndex) {
              practiceDetails[prevIndex].plantName.push(
                practiceDetails[pos].plantName[0]
              );
              practiceDetails[prevIndex].indexPlant.push(
                practiceDetails[pos].indexPlant[0]
              );
              practiceDetails[prevIndex].plantDetails.push(
                practiceDetails[pos].plantDetails[0]
              );
            }

            return pos === prevIndex;
          });
          practiceDetails = practiceDetails
            .slice()
            .sort((a, b) => a.practiceNo - b.practiceNo);
          practiceNoList.push({
            pillarCode: pillar,
            practiceDetails: practiceDetails,
          });
        }
      });

      for (let i = 0; i < practiceNoList.length; i++) {
        for (let j = 0; j < practiceNoList[i].practiceDetails.length; j++) {
          let plantDetails = [];
          for (let k = 0; k < this.plantList.length; k++) {
            let index = practiceNoList[i].practiceDetails[j].indexPlant;
            if (index.indexOf(k) !== -1) {
              plantDetails.push(
                practiceNoList[i].practiceDetails[j].plantDetails[
                index.indexOf(k)
                ]
              );
            } else {
              plantDetails.push({ smartAction: "", status: "" });
            }
          }
          practiceNoList[i].practiceDetails[j].plantDetails = plantDetails;
        }
      }
      this.DraftList = practiceNoList;
      this.dataStatus = true;
      this.showLoader = false;
    },

    setFilterForAction() {
      if (this.pillarDataBackup && this.pillarDataBackup.pillarData) {
        let regionListArray = this.plantListFromAPI.map(
          (value) => value.regionCode
        );
        regionListArray = regionListArray.filter(function (item, pos) {
          return item !== null && regionListArray.indexOf(item) == pos;
        });
        regionListArray = regionListArray.sort((a, b) =>
          a !== b ? (a < b ? -1 : 1) : 0
        );
        let checkedRegionNames = this.filterSelected[0].arrayValue.map(
          (value) => {
            if (value.checked === true) {
              return value.label;
            } else return "";
          }
        );

        checkedRegionNames = checkedRegionNames.filter((value) => value !== "");
        for (let i = 0; i < regionListArray.length; i++) {
          regionListArray[i] = {
            value: regionListArray[i],
            label: regionListArray[i],
            checked:
              (this.loggedInUserData.regionName &&
                this.loggedInUserData.regionName == regionListArray[i]) ||
                (checkedRegionNames.includes(regionListArray[i]) &&
                  this.onlyOneTime)
                ? true
                : false,
          };
        }
        regionListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: false,
        });
        let regionPBUArray = this.pillarDataBackup.pillarData.map(
          (value) => value.regionPBU
        );
        regionPBUArray = regionPBUArray.filter(function (item, pos) {
          return item !== null && regionPBUArray.indexOf(item) == pos;
        });
        for (let i = 0; i < regionPBUArray.length; i++) {
          regionPBUArray[i] = {
            value: regionPBUArray[i],
            label: regionPBUArray[i],
            checked: false,
          };
        }
        let plantNameArray = this.plantListFromAPI.map((value) => {
          return {
            id: value.plantId,
            label: value.plantName + ", " + value.regionCode,
          };
        });
        plantNameArray = plantNameArray.filter(function (item, pos) {
          return item !== null && plantNameArray.indexOf(item) == pos;
        });
        plantNameArray = plantNameArray.sort((a, b) =>
          a.label.split(", ")[1] !== b.label.split(", ")[1]
            ? a.label.split(", ")[1] < b.label.split(", ")[1]
              ? -1
              : 1
            : 0
        );
        let checkedPlantNames = this.filterSelected[1].arrayValue.map(
          (value) => {
            if (value.checked === true) {
              return value.label;
            } else return "";
          }
        );

        checkedPlantNames = checkedPlantNames.filter((value) => value !== "");
        for (let i = 0; i < plantNameArray.length; i++) {
          plantNameArray[i] = {
            value: plantNameArray[i].id,
            label: plantNameArray[i].label,
            checked:
              (this.loggedInUserData.plantId &&
                this.loggedInUserData.plantId == plantNameArray[i].id &&
                this.isPlantUser) ||
                (checkedPlantNames.includes(plantNameArray[i].label) &&
                  this.onlyOneTime)
                ? true
                : false,
          };
        }
        plantNameArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: false,
        });
        let pillarListArray = this.pillarListFromAPI.map(
          (value) => value.pillarCode
        );
        pillarListArray = pillarListArray.filter(function (item, pos) {
          return item !== null && pillarListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < pillarListArray.length; i++) {
          pillarListArray[i] = {
            value: pillarListArray[i],
            label: pillarListArray[i],
            checked: true,
          };
        }

        pillarListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true,
        });
        let principleListArray = this.pillarDataBackup.pillarData.map(
          (value) => value.principleNo
        );
        principleListArray = principleListArray.filter(function (item, pos) {
          return item !== null && principleListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < principleListArray.length; i++) {
          principleListArray[i] = {
            value: principleListArray[i],
            label: principleListArray[i],
            checked: true,
          };
        }
        principleListArray = principleListArray.sort((a, b) =>
          a !== b ? (a < b ? -1 : 1) : 0
        );
        principleListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true,
        });
        let levelListArray = this.pillarDataBackup.pillarData.map(
          (value) => value.levelNo
        );
        levelListArray = levelListArray.filter(function (item, pos) {
          return item !== null && levelListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < levelListArray.length; i++) {
          levelListArray[i] = {
            value: levelListArray[i],
            label: levelListArray[i],
            checked: true,
          };
        }
        levelListArray = levelListArray.sort((a, b) =>
          parseInt(a.value) !== parseInt(b.value)
            ? parseInt(a.value) < parseInt(b.value)
              ? -1
              : 1
            : 0
        );
        levelListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true,
        });
        this.filterArray = [];
        this.filterArray.push({
          labelName: "Region",
          arrayValue: regionListArray,
          id: "Region",
          multiple: true,
        });
        if (regionPBUArray.length > 0)
          this.filterArray.push({
            labelName: "Region - PBU",
            arrayValue: regionPBUArray,
            id: "PBU",
            multiple: true,
          });
        this.filterArray.push({
          labelName: "Plant Name",
          arrayValue: plantNameArray,
          id: "PlantName",
          multiple: true,
        });
        this.filterArray.push({
          labelName: "Pillar Name",
          arrayValue: pillarListArray,
          id: "PillarName",
          multiple: true,
        });
        this.filterArray.push({
          labelName: "Principle Number",
          arrayValue: principleListArray,
          id: "PrincipleNumber",
          multiple: true,
        });
        this.filterArray.push({
          labelName: "Level",
          arrayValue: levelListArray,
          id: "Level",
          multiple: true,
        });
        this.filterSelected = this.filterArray;
      } else {
        let regionListArray = this.plantListFromAPI.map(
          (value) => value.regionCode
        );
        regionListArray = regionListArray.filter(function (item, pos) {
          return item !== null && regionListArray.indexOf(item) == pos;
        });
        regionListArray = regionListArray.sort((a, b) =>
          a !== b ? (a < b ? -1 : 1) : 0
        );
        for (let i = 0; i < regionListArray.length; i++) {
          regionListArray[i] = {
            value: regionListArray[i],
            label: regionListArray[i],
            checked:
              this.loggedInUserData.regionName &&
                this.loggedInUserData.regionName == regionListArray[i]
                ? true
                : false,
          };
        }
        regionListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: false,
        });
        let regionPBUArray = this.plantListFromAPI.map(
          (value) => value.regionPBU
        );
        regionPBUArray = regionPBUArray.filter(function (item, pos) {
          return item !== null && regionPBUArray.indexOf(item) == pos;
        });
        for (let i = 0; i < regionPBUArray.length; i++) {
          regionPBUArray[i] = {
            value: regionPBUArray[i],
            label: regionPBUArray[i],
            checked: false,
          };
        }
        let plantNameArray = this.plantListFromAPI.map((value) => {
          return {
            id: value.plantId,
            label: value.plantName + ", " + value.regionCode,
          };
        });
        plantNameArray = plantNameArray.filter(function (item, pos) {
          return item !== null && plantNameArray.indexOf(item) == pos;
        });
        plantNameArray = plantNameArray.sort((a, b) =>
          a.label.split(", ")[1] !== b.label.split(", ")[1]
            ? a.label.split(", ")[1] < b.label.split(", ")[1]
              ? -1
              : 1
            : 0
        );
        for (let i = 0; i < plantNameArray.length; i++) {
          plantNameArray[i] = {
            value: plantNameArray[i].id,
            label: plantNameArray[i].label,
            checked:
              this.loggedInUserData.plantId &&
                this.loggedInUserData.plantId == plantNameArray[i].id &&
                this.isPlantUser
                ? true
                : false,
          };
        }
        plantNameArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: false,
        });
        let pillarListArray = this.pillarListFromAPI.map(
          (value) => value.pillarCode
        );
        pillarListArray = pillarListArray.filter(function (item, pos) {
          return item !== null && pillarListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < pillarListArray.length; i++) {
          pillarListArray[i] = {
            value: pillarListArray[i],
            label: pillarListArray[i],
            checked: true,
          };
        }

        pillarListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true,
        });

        this.filterArray = [];
        this.filterArray.push({
          labelName: "Region",
          arrayValue: regionListArray,
          id: "Region",
          multiple: true,
        });
        if (regionPBUArray.length > 1)
          this.filterArray.push({
            labelName: "Region - PBU",
            arrayValue: regionPBUArray,
            id: "PBU",
            multiple: true,
          });
        this.filterArray.push({
          labelName: "Plant Name",
          arrayValue: plantNameArray,
          id: "PlantName",
          multiple: true,
        });
        this.filterArray.push({
          labelName: "Pillar Name",
          arrayValue: pillarListArray,
          id: "PillarName",
          multiple: true,
        });
        this.filterSelected = this.filterArray;
      }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    applyFilter() {
      let filterSelected = this.filterSelected;
      this.showLoader = true;
      this.dataStatus = false;
      let plantIdsArr = [];
      filterSelected[1].arrayValue.forEach((i) => {
        if (i.checked === true && i.value != "SelectAll") {
          plantIdsArr.push(i.value);
        }
      });
      let pillarCodeArr = [];
      filterSelected[2].arrayValue.forEach((i) => {
        if (i.checked === true && i.value != "SelectAll") {
          pillarCodeArr.push(i.value);
        }
      });

      setTimeout(() => {
        this.getGapClosureData(plantIdsArr, pillarCodeArr);
      }, 100);
      this.hideModal();
    },
    resetFilter() {
      this.showLoader = true;
      this.filterArray = [];
      this.filterSelected = this.filterArray;
      this.getPlantList();
      this.hideModal();
    },
    onSelect(option, index) {
      if (option.checked === true) {
        if (option.value === "SelectAll") {
          this.filterSelected[index].arrayValue[0].checked = false;
          const lenght = this.filterSelected[index].arrayValue.length
          for (let i = 0; i < lenght; i++) {
            this.filterSelected[index].arrayValue[i].checked = false;
          }
          if (index === 0) {
            this.filterSelected[1].arrayValue[0].checked = false;
          }
        } else {
          let indexLocal = this.filterSelected[index].arrayValue.findIndex(
            (item) => item.label == option.label
          );

          this.filterSelected[index].arrayValue[indexLocal].checked = false;
        }
        if (index === 0) {
          if (option.value === "SelectAll") {
            for (let i = 0; i < this.filterSelected[1].arrayValue.length; i++) {
              this.filterSelected[1].arrayValue[i].checked = false;
            }
            if (index === 1) {
              this.filterSelected[1].arrayValue[0].checked = false;
            }
          } else {
            for (let a = 0; a < this.filterSelected[0].arrayValue.length; a++) {
              let region = this.filterSelected[0].arrayValue[a].label;
              let checkedRegion = this.filterSelected[0].arrayValue[a].checked;
              const lengthToCheck = this.filterSelected[1].arrayValue.length;
              for (
                let b = 0;
                b < lengthToCheck;
                b++
              ) {
                let regionPlant = this.filterSelected[1].arrayValue[b].label;
                if (region === regionPlant.split(", ")[1]) {
                  this.filterSelected[1].arrayValue[b].checked = checkedRegion;
                }
              }
            }
          }
        }

        let countAll = 0;
        let countUn = 0;
        let j = 0;

        for (j = 0; j < this.filterSelected[index].arrayValue.length; j++) {
          if (this.filterSelected[index].arrayValue[j].checked === true) {
            countAll++;
          }
          if (this.filterSelected[index].arrayValue[j].checked === false) {
            countUn++;
          }

          if (j === this.filterSelected[index].arrayValue.length - 1) {
            if (countAll === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = true;
              if (index === 0) {
                this.filterSelected[1].arrayValue[0].checked = true;
              }
            }
            if (countUn === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 0) {
                this.filterSelected[1].arrayValue[0].checked = false;
              }
            }

            if (
              countAll !== this.filterSelected[index].arrayValue.length - 1 &&
              countUn !== this.filterSelected[index].arrayValue.length - 1
            ) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 0) {
                this.filterSelected[1].arrayValue[0].checked = false;
              }
            }
            if (
              index === 0 &&
              this.filterSelected[index].arrayValue[0].checked === true
            ) {
              this.filterSelected[1].arrayValue[0].checked = true;
            } else if (
              index === 0 &&
              this.filterSelected[index].arrayValue[0].checked === false
            ) {
              this.filterSelected[1].arrayValue[0].checked = false;
            }
          }
        }

        //// console.log(
        //   option.label + "  Clicked!! ",
        //   this.filterSelected[index].arrayValue
        // );
      } else {
        this.onRemove(option, index);
        if (index === 0) {
          this.filterSelected[1].arrayValue[0].checked = false;
          //// console.log(this.filterSelected[2].arrayValue[0])
        }
      }
    },
    onRemove(option, index) {
      if (option.checked === false) {
        if (option.value === "SelectAll") {
          this.filterSelected[index].arrayValue[0].checked = true;
          let length = this.filterSelected[index].arrayValue.length
          for (
            let i = 0;
            i < length;
            i++
          ) {
            this.filterSelected[index].arrayValue[i].checked = true;
          }
          if (index === 0) {
            this.filterSelected[1].arrayValue[0].checked = true;
          }
        } else {
          let indexL = this.filterSelected[index].arrayValue.findIndex(
            (item) => item.label == option.label
          );
          this.filterSelected[index].arrayValue[indexL].checked = true;
        }
        if (index === 0) {
          if (option.value === "SelectAll") {
            for (let i = 0; i < this.filterSelected[1].arrayValue.length; i++) {
              this.filterSelected[1].arrayValue[i].checked = true;
            }
            if (index === 0) {
              this.filterSelected[1].arrayValue[0].checked = true;
            }
          } else {
            for (let a = 0; a < this.filterSelected[0].arrayValue.length; a++) {
              let region = this.filterSelected[0].arrayValue[a].label;
              let checkedRegion = this.filterSelected[0].arrayValue[a].checked;
              for (
                let b = 0;
                b < this.filterSelected[1].arrayValue.length;
                b++
              ) {
                let regionPlant = this.filterSelected[1].arrayValue[b].label;
                if (region === regionPlant.split(", ")[1]) {
                  this.filterSelected[1].arrayValue[b].checked = checkedRegion;
                }
              }
            }
          }
        }

        let countAll = 0;
        let countUn = 0;
        let j = 0;
        for (j = 0; j < this.filterSelected[index].arrayValue.length; j++) {
          if (this.filterSelected[index].arrayValue[j].checked === true) {
            countAll++;
          }
          if (this.filterSelected[index].arrayValue[j].checked === false) {
            countUn++;
          }

          if (j === this.filterSelected[index].arrayValue.length - 1) {
            if (countAll === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = true;
              if (index === 0) {
                this.filterSelected[1].arrayValue[0].checked = true;
              }
            }
            if (countUn === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 0) {
                this.filterSelected[1].arrayValue[0].checked = false;
              }
            }

            if (
              countAll !== this.filterSelected[index].arrayValue.length - 1 &&
              countUn !== this.filterSelected[index].arrayValue.length - 1
            ) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 0) {
                this.filterSelected[1].arrayValue[0].checked = false;
              }
            }
            if (
              index === 0 &&
              this.filterSelected[index].arrayValue[0].checked === true
            ) {
              this.filterSelected[1].arrayValue[0].checked = true;
            } else if (
              index === 0 &&
              this.filterSelected[index].arrayValue[0].checked === false
            ) {
              this.filterSelected[1].arrayValue[0].checked = false;
            }
          }
        }
        if (index === 1) {
          let regionName = option.label.split(", ")[1];
          //console.log(this.filterSelected[0],option.label, regionName)
          this.filterSelected[0].arrayValue.map((value) => {
            if (value.label === regionName && value.checked === false)
              value.checked = true;
          });
        }
      } else {
        this.onSelect(option, index);
        if (index === 0) {
          this.filterSelected[1].arrayValue[0].checked = true;
          //// console.log(this.filterSelected[2].arrayValue[0])
        }
      }
    },
    changedFilter(event) {
      let filterSelectedC = [];
      this.filterArray.map((val, index) => {
        // // console.log(
        //   val,
        //   " \n val.id ",
        //   val.id,
        //   "\n event.target.id ",
        //   event.target.id,
        //   "\n event.target.value ",
        //   event.target.value
        // );
        if (
          val.id === event.target.id &&
          event.target.value !== val.id &&
          val.arrayValue.includes(event.target.value)
        ) {
          let temp = {
            id: val.id,
            arrayValue: [event.target.value],
            labelName: val.labelName,
          };
          //// console.log(val, "if ", temp);
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (
          val.id === event.target.id &&
          val.id === event.target.value
        ) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          // // console.log(val, "else if same ", temp);
          return filterSelectedC;
        } else if (
          val.id !== event.target.id &&
          event.target.value !== val.id &&
          val.arrayValue.length ===
          this.filterSelected[index]["arrayValue"].length
        ) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName,
          };
          filterSelectedC.push(temp);
          //// console.log(val, "else ", temp);
          return filterSelectedC;
        } else {
          // // console.log(val, "else ", this.filterSelected[index]);
          filterSelectedC.push(this.filterSelected[index]);
        }
      });
      this.filterSelected = filterSelectedC;
      // // console.log("changedFilter", this.filterSelected);
    },
  },
};
</script>
<style scoped>
#chart {
  padding: 0 2rem 2rem 0;
}

.outer-block {
  background-color: #fff;
  border-radius: 6px;
}

.hr {
  margin: 1rem 2rem !important;
}

.tab-titles {
  padding: 2rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-options {
  margin-right: 1rem;
  display: flex;
}

.tab-option-text {
  font: 1.16rem "BarlowR", sans-serif;
  cursor: pointer;
}

.in-action {
  margin-right: 1.688em;
}

.tab-active-option {
  font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
  border-top: 3px #274ba7 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 1rem;
}

.select-dd {
  width: 10%;
}

.button-dd {
  display: inline-flex;
}

.funnel-con {
  /* border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; */
  font-size: 1.33rem;
}

.sample-span {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

.sample-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 !important;
}

.title-text {
  font: 1.33rem "BarlowSb", sans-serif;
  padding: 0 0 2rem 2rem;
}

#reports-catv-data-table th div {
  color: #313131;
}

#reports-catv-data-table tbody tr:nth-child(odd) {
  background-color: #fafafa !important;
}

#reports-catv-data-table tbody tr:nth-child(even):hover {
  background-color: #fff !important;
}

#reports-catv-data-table tr td:first-child {
  padding-left: 1.25em !important;
}

#reports-catv-data-table tr th:first-child {
  padding-left: 1.25em !important;
}

.download-view {
  border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.download-view:hover {
  background: #e3e8f7;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

.action-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  width: 288px !important;
  height: 7rem;
  color: #5c5e62;
  resize: none;
}
</style>